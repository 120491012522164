<template src="./SimpleCV.html"/>
<script>
import "jspdf/dist/polyfills.es.js";
import moment from "moment";
import { putFile } from "@/services/files";
import html2pdf from "html2pdf.js";
import jobsService from "@/services/Jobs";
import McFontpicker from '@mikk3lro/mc-fontpicker';
import '@mikk3lro/mc-fontpicker/dist/mc-fontpicker.css';
export default {
  name: "SimpleCV",
  components: {
    McFontpicker
  },
  data() {
    return {
      fontFamily: "Roboto",
      name: "Jhon Smith",
      profession: "Graphic & web designer",
      aboutMe: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras non pretium velit, ac auctor mi. Nulla vel lacus at arcu cursus auctor. Aenean velit urna, gravida eu tortor nec, eleifend lobortis nisl. Etiam mattis blandit finibus. Aliquam velit neque, tempor porttitor augue sit amet, eleifend varius dui. Aenean sed ultricies ligula. In pretium nisl arcu, vitae facilisis sapien aliquam id. Integer convallis sit amet nulla id euismod. Maecenas eu dui eu dui ultrices feugiat. In gravida lorem sed enim consequat, eget ullamcorper sem finibus. In a aliquam metus. Proin quis leo feugiat, scelerisque augue sed, maximus diam. Curabitur facilisis tortor eget dui dapibus, eu dignissim leo ornare. Duis ultricies dolor massa, ut vestibulum magna hendrerit id. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras non pretium velit, ac auctor mi. Nulla vel lacus at arcu cursus auctor. Aenean velit urna, gravida eu tortor nec, eleifend lobortis nisl. Etiam mattis blandit finibus. Aliquam velit neque, tempor porttitor augue sit amet, eleifend varius dui. Aenean sed ultricies ligula. In pretium nisl arcu, vitae facilisis sapien aliquam id. Integer convallis sit amet nulla id euismod. Maecenas eu dui eu dui ultrices feugiat. In gravida lorem sed enim consequat, eget ullamcorper sem finibus. In a aliquam metus. Proin quis leo feugiat, scelerisque augue sed, maximus diam. Curabitur facilisis tortor eget dui dapibus, eu dignissim leo ornare. Duis ultricies dolor massa, ut vestibulum magna hendrerit id.",
      phone: "00 1234 567 889",
      email: "yourname55@gmail.com",
      website: "www.yourdomainname.com",
      address: "223, street, New york ABC Your City 568",
      colorPicker: '#f4f4f4',
      fontTitlePicker: '#4a4a4a',
      fontTextPicker: '#65676d',
      fontExperienceTitlePicker: '#57acde',
      optionsFont: {
        name: 'sapiencia',
        variants: ['regular', 'italic', '700', '700italic']
      },
      socialNetworks: [
        {
          socialNetworkName: "behance",
          link: "behance.net/username"
        },
        {
          socialNetworkName: "FacebookAcount",
          link: "facebook.com/username"
        },
        {
          socialNetworkName: "twitter",
          link: "twitter.com/username"
        },
      ],
      languages: [
        { language: "English", level: 4 },
        { language: "German", level: 3 },
        { language: "French", level: 1 }
      ],
      jobs:
        [
          {
            title: "Lead web designer",
            dates: {
              start: '2005',
              end: '2007'
            },
            subtitle: 'lorem ipsu bla bla',
            description: 'orttitor amet massa Done cporttitor dolor et porttitor amet shit am et massa Done \n' +
              'cporttitor dolor molestie pretium fe liscon lore imolestie pretium. liberosn.'
          },
          {
            title: "Lead web designer",
            dates: {
              start: '2005',
              end: '2007'
            },
            subtitle: 'lorem ipsu bla bla',
            description: 'orttitor amet massa Done cporttitor dolor et porttitor amet shit am et massa Done \n' +
              'cporttitor dolor molestie pretium fe liscon lore imolestie pretium. liberosn.'
          },
          {
            title: "Lead web designer",
            dates: {
              start: '2005',
              end: '2007'
            },
            subtitle: 'lorem ipsu bla bla',
            description: 'orttitor amet massa Done cporttitor dolor et porttitor amet shit am et massa Done \n' +
              'cporttitor dolor molestie pretium fe liscon lore imolestie pretium. liberosn.'
          },
          {
            title: "Lead web designer",
            dates: {
              start: '2005',
              end: '2007'
            },
            subtitle: 'lorem ipsu bla bla',
            description: 'orttitor amet massa Done cporttitor dolor et porttitor amet shit am et massa Done \n' +
              'cporttitor dolor molestie pretium fe liscon lore imolestie pretium. liberosn.'
          },
          {
            title: "Lead web designer",
            dates: {
              start: '2005',
              end: '2007'
            },
            subtitle: 'lorem ipsu bla bla',
            description: 'orttitor amet massa Done cporttitor dolor et porttitor amet shit am et massa Done \n' +
              'cporttitor dolor molestie pretium fe liscon lore imolestie pretium. liberosn.'
          },
        ],
      education:
        [
          {
            university: "Lead web designer",
            dates: {
              start: '2005',
              end: '2007'
            },
            description: 'orttitor amet massa Done cporttitor dolor et porttitor amet shit am et massa Done \n' +
              'cporttitor dolor molestie pretium fe liscon lore imolestie pretium. liberosn.'
          }, {
            university: "Lead web designer",
            dates: {
              start: '2005',
              end: '2007'
            },
            description: 'orttitor amet massa Done cporttitor dolor et porttitor amet shit am et massa Done \n' +
              'cporttitor dolor molestie pretium fe liscon lore imolestie pretium. liberosn.'
          },
        ],
      skills: [
        {
          name: "Wordpress",
          progress: '20'
        },
        {
          name: "Communication",
          progress: '50'
        },
        {
          name: "Communication",
          progress: '70'
        }, {
          name: "Communication",
          progress: '70'
        },
      ],
      reference: [
        {
          name: "Paul Anderson",
          position: 'Web Developing LTD',
          phone: "00000000",
          email: 'email@gmail.com'
        },
        {
          name: "Paul Anderson",
          position: 'Web Developing LTD',
          phone: "00000000",
          email: 'email@gmail.com'
        },
      ]
    }
  },
  watch: {
    "colorPicker"(newValue) {
      this.colorPicker = newValue;
    },
    "fontTitlePicker"(newValue) {
      this.fontTitlePicker = newValue;
    },
    "fontTextPicker"(newValue) {
      this.fontTextPicker = newValue;
    },
    "fontExperienceTitlePicker"(newValue) {
      this.fontExperienceTitlePicker = newValue;
    }
  },
  mounted() {
    this.$store.dispatch('fetchLoading', true);
    const dataProps = this.$store.getters.getCv.form.firstStage;
    if (dataProps) {
      this.name = dataProps.personalInformation.name;
      this.firstSurname = dataProps.personalInformation.firstSurname;
      this.secondSurname = dataProps.personalInformation.secondSurname;
      this.profession = dataProps.workingInformation.workArea;
      this.aboutMe = dataProps.workingInformation.aboutMe;
      this.phone = dataProps.personalInformation.cellPhone;
      this.mail = dataProps.personalInformation.mail;
      this.website = dataProps.personalInformation.pageWebPersonal;
      this.address = dataProps.personalInformation.residenceDepartment + " - " + dataProps.personalInformation.cityResidence;
      const lenguajes = [];
      for (const language of dataProps.languages) {
        switch (language.level) {
          case "Avanzado":
            lenguajes.push({
              language: language.name,
              level: 3
            });
            break;
          case "Intermedio":
            lenguajes.push({
              language: language.name,
              level: 2
            });
            break;
          case "Básico":
            lenguajes.push({
              language: language.name,
              level: 1
            });
            break;
          default:
            break;
        }
      }
      this.languages = lenguajes;
      const jobs = [];
      for (const job of dataProps.workExperience) {
        jobs.push({
          title: job.position,
          dates: {
            start: job.startDate,
            end: job.endDate
          },
          subtitle: job.companyName,
          description: job.jobDescription
        });
      }
      this.jobs = jobs;
      const educations = [];
      for (const education of dataProps.education) {
        educations.push({
          university: education.educationalInstitution,
          dates: {
            start: education.startDate,
            end: education.endDate
          },
          description: education.educationalDiscipline
        });
      }
      this.education = educations;
      const socialNetworks = [];
      for (const socialNetwork of dataProps.socialNetworks) {
        socialNetworks.push({
          socialNetworkName: socialNetwork.socialNetworkName,
          link: socialNetwork.link
        });
      }
      this.socialNetworks = socialNetworks;
      const skills = [];
      for (const skill of dataProps.skills) {
        switch (skill.level) {
          case "Avanzado":
            skills.push({
              name: skill.name,
              level: 3
            });
            break;
          case "Intermedio":
            skills.push({
              name: skill.name,
              level: 2
            });
            break;
          case "Básico":
            skills.push({
              name: skill.name,
              level: 1
            });
            break;
          default:
            break;
        }
      }
      this.skills = skills;
      const references = [];
      for (const reference of dataProps.references) {
        references.push({
          name: reference.name,
          position: reference.position,
          phone: reference.contactNumber,
          email: reference.mail
        });
      }
      this.reference = references;
      //this.imageCertificateToPdfFirebase();
    }
    this.$store.dispatch('fetchLoading', false);
  },
  methods: {
    cvDownload() {
      this.$store.dispatch('fetchLoading', true);
      this.imageCertificateToPdfFirebase();
    },
    async createCanvas() {
      const content = document.getElementById('page1-div');
      let nameFile = "";
      const name = this.$store.getters.getCv.name;
      if (name) {
        nameFile = name
      } else {
        nameFile = `hv-${Math.floor(Math.random() * (9999999 - 1000) + 1000)}.pdf`;
      }
      const options = {
        filename: nameFile,
        margin: [1, 1],
        image: { type: 'png', quality: 0.4 },
        html2canvas: {
          dpi: 200,
          letterRendering: true,
          scale: 2,
          width: 900,
        },
        jsPDF: { unit: 'mm', format: 'legal', orientation: 'portrait', compress: true }
      }
      return { options: options, content: content }
    },
    async imageCertificateToPdfFirebase() {
      try {
        let { options, content } = await this.createCanvas()
        let worker = await html2pdf().set(options).from(content).toPdf().output('blob').then((data) => {
          return data
        });
        const url = await putFile(
          worker,
          "CV-PDF",
          `${options.filename}`
        );
        const dataCv = this.$store.getters.getCv;
        const data =
        {
          name: options.filename,
          url: url,
          curriculumTemplate: dataCv.curriculumTemplate,
          form: dataCv.form
        }
        if (this.$route.query.curriculumId) {
          jobsService.updateCurriculum({
            id: this.$route.query.curriculumId,
            data: data
          });
        } else {
          jobsService.createdCurriculum(data);
        }
        window.open(url, '_blank');
        this.$store.dispatch('fetchLoading', false);
        this.$router.push("/empleo?enableCV=true");
      } catch (error) {
        console.error(error);
      }
    },
    dateFormat(date) {
      return moment(date).format('YYYY-MM-DD');
    }
  },

}
</script>

<style lang="scss" scoped src="./SimpleCV.scss">

</style>
